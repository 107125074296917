import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Faq from '../components/Faq'
import CarouselFeatures from '../components/CarouselFeatures'
import SliderFeatures from '../components/SliderFeatures'
import BlocImageGaucheTexte from '../components/BlocImageGaucheTexte'
import BlocImageDroiteTexte from '../components/BlocImageDroiteTexte'
import BlocJauneTexte from '../components/BlocJauneTexte'
import BlocImageFullWidth from '../components/BlocImageFullWidth'
import BlocVertTexteCTA from '../components/BlocVertTexteCTA'
import Testimonials from '../components/Testimonials'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SEO from '../components/SEO'
import Calendly from '../components/Calendly'

function SolutionAssetManagers (props) {
  const [language, setLanguage] = useState('1')
  const [nodeLocale, setNodeLocale] = useState('')
  const [popupCalendly, setPopupCalendly] = useState(false)

  const getLanguage = (data) => {
    if (data === '0') {
      setLanguage(0)
      setNodeLocale('en-US')
    } else if (data === '1') {
      setLanguage(1)
      setNodeLocale('fr')
    }
  }

  const closePopupCalendly = () => {
    setPopupCalendly(false)
  }

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem('language') === '0') {
        setLanguage(0)
        setNodeLocale('en-US')
      } else if ((localStorage.getItem('language')) === '1') {
        setLanguage(1)
        setNodeLocale('fr')
      }
    }
  }, [nodeLocale])

  const data = props.data
  const {
    headline,
    excerpt,
    blocCtaBtns,
    heroImage,
    modularArea
  } = data.allContentfulSolution.nodes[`${language}`]
  const header = data.allContentfulSousMenu.nodes
  const postNode = data.allContentfulSolution.nodes[`${language}`]

  return (
    <div>
      <Header headerContent={header} infoPage={props} language={language} getLanguage={getLanguage}/>
      <Layout data={data}>
        <SEO pagePath="/solution-asset-managers" postNode={postNode} pageSEO />

        <div className="wrapper" id="wrapper-solutions">
          <main className="solutions solutions-asset-manager">
            <section className="section-1">
              <div className="container">
                <div className="row">
                  <div className="columns col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div className="box-item">
                      <h1 className="headline">{headline}</h1>
                      <h2 className="description">{excerpt}</h2>
                      <div className="box-btns">
                        <button className="btn btn-primary" onClick={(e) => setPopupCalendly(true)}><span>{blocCtaBtns.ctaLienGauche}</span></button>
                        <Link to={blocCtaBtns.ctaUrlLienDroite} title=""><button className="btn btn-primary btn-blanc"><span>{blocCtaBtns.ctaLienDroite}</span></button></Link>
                      </div>
                    </div>
                  </div>
                  <div className="columns col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div className="box-bitmap">
                      <img src={heroImage.file.url} alt={heroImage.description}/>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {modularArea.map((item) =>
              item.typeBlocImage === 'Image à gauche' ? <BlocImageGaucheTexte data={item}/> :
              item.typeBlocImage === 'Image à droite' ? <BlocImageDroiteTexte data={item}/> :
              item.typeBlocImage === 'Fond jaune' ? <BlocJauneTexte data={item}/> :
              item.typeBlocImage === '2 Col Headline & Texte' ? <BlocImageFullWidth data={item}/> :
              item.typeBlocImage === 'Fond Vert & CTA' ? <BlocVertTexteCTA data={item}/> :
              item.__typename === 'ContentfulFaq' ? <Faq data={item}/> :
              item.__typename === 'ContentfulCarouselClients' ? <Testimonials data={item}/> :
              item.__typename === 'ContentfulCarouselFeatures' ?
                <section className="section-carousel-features">
                  <div className="container">
                    <h2 className="large">{item.headline}</h2>
                    <CarouselFeatures data={item}/>
                  </div>
                </section> :
              item.__typename === 'ContentfulSliderFeatures' ? <SliderFeatures data={item} language={language}/> : null
            )}

            <Calendly data={popupCalendly} closePopup={closePopupCalendly}/>

          </main>
        </div>
      </Layout>
      <Footer getLanguage={getLanguage}/>
    </div>
  )
}

export const query = graphql`
  query SolutionAssetManagersQuery {
    allContentfulSousMenu {
      nodes {
        contentful_id
        node_locale
        titre
        icone {
          file {
            url
          }
        }
        baselineMenu {
          baselineMenu
        }
      }
    }
    allContentfulSolution(filter:{contentful_id:{eq:"7xWlLjxT3h8XN7qvqmR1Fb"}}) {
      nodes {
        id
        node_locale
        titre
        metaDescription {
          metaDescription
          internal {
            content
          }
        }
        headline
        excerpt
        blocCtaBtns {
          ctaLienDroite
          ctaLienGauche
          ctaTexteDroite {
            ctaTexteDroite
          }
          ctaTexteGauche {
            ctaTexteGauche
          }
          ctaUrlLienDroite
          ctaUrlLienGauche
        }
        heroImage {
          description
          file {
            url
          }
        }
        modularArea {
          ... on ContentfulBlocImageTexte {
            headline
            typeBlocImage
            lienInterne
            lienTitreCta
            image {
              description
              file {
                url
              }
              contentful_id
            }
            texte {
              texte
            }
            listePucesDeFeatures {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulCarouselFeatures {
            __typename
            headline
            features {
              ... on ContentfulCardFeature {
                id
                headline
                description
                image {
                  file {
                    url
                  }
                  description
                }
              }
            }
          }
          ... on ContentfulCarouselClients {
            __typename
            headline
            id
            clients {
              logo {
                description
                file {
                  url
                }
              }
              nom
              poste
              citation {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          ... on ContentfulSliderFeatures {
            __typename
            id
            headline
            features {
              headline
              description
              node_locale
              imageSlider {
                file {
                  url
                }
                description
              }
              lien
            }
            node_locale
          }
          ... on ContentfulFaq {
            __typename
            question1
            question2
            question3
            question4
            question5
            question6
            question7
            question8
            rponse1 {
              rponse1
            }
            rponse2 {
              rponse2
            }
            rponse3 {
              rponse3
            }
            rponse4 {
              rponse4
            }
            rponse5 {
              rponse5
            }
            rponse6 {
              rponse6
            }
            rponse7 {
              rponse7
            }
            rponse8 {
              rponse8
            }
          }
        }
      }
    }
  }
`

export default SolutionAssetManagers
